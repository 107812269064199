import React from "react"
import { Link } from "gatsby"
import { Destini } from '@tyson-foods/react-component-library'
import Layout from "../components/layout"
import SEO from "../components/seo"

const SecondPage = () => (
    <Layout metaTitle="Where to Buy Our Bacon"
        metaDescription="Locate where to buy Wright® Brand thick-cut bacon near you & get ready to join the Wright Brand family. Warning: once you try our bacon, you'll never want another."
        metaKeywords="bacon brands, beef bacon walmart, best bacon brands, brands of bacon"
    >

        <article class="page-section ">
            <div class="image"><img src={'/tysonscore3.azureedge.net/assets/media/wrightbrand/images/backgrounds/wrightbrand_productlocatorimage.png'} alt="Product Locator"></img>
            </div>
        </article>
        <div class="page-title-description">
            <div class="title-description-container container">
                <h1>Where to Buy Our Bacon Products</h1>
                <p>
                    Use the dropdowns below to search for stores that sell our products in your area. Having trouble finding Wright Brand bacon products in your area? Contact us or ask for Wright Brand at your local grocer.
        </p>
            </div>
        </div>

        <div  >
            <Destini siteID="wrightbrand" />
        </div>
    </Layout>
)

export default SecondPage
